import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import Judge1 from '../images/icons/186325-justice/svg/judge-1.svg';
import { Layout } from '../components/common';

const NotFoundPage = () => (
  <>
    <Helmet>
      <title>Rent Control</title>
      <meta
        name="description"
        content="Millions of people rent not knowing the body of laws around it. rentcontrol.io exists to help renters and help them know laws that enable and protect them."
      />
    </Helmet>
    <Layout>
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container content has-text-centered">
            <figure className="image is-256x256 is-centered">
              <img src={Judge1} />
            </figure>
            <h1 className="title is-1">This page isn&apos;t ready yet</h1>
            <Link to="/" title="go back to home page">
              Go back to the home page
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
